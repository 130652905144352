






















import Vue from 'vue';

export default Vue.extend({
  name: 'Otp',

  data(): unknown {
    return {
      otp: '',
      loading: false,
    }
  },

  methods: {
    validate () {
      this.loading = true
      this.$http.post('/auth/otp', { token: localStorage.getItem('token'), code: this.otp }).then((response) => {
        localStorage.setItem('token', response.data);
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data;
        this.loading = false;
        this.$router.push({ name: 'Main' });
      }).catch(() => {
        this.loading = false;
        this.otp = '';
        this.$toast.error('Invalid code', {
          position: 'bottom-center',
          timeout: 3000,
        });
        this.$refs.otp.focus();
      });
    },
  },
});
